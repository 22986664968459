/*
 * booking.tsx
 *
 * Description: Displays Booking Screen with Search- and Found Card.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import React, { useRef } from 'react';
import { 
    IonPage, 
    IonHeader, 
    IonContent, 
    IonToolbar, 
    IonTitle
 } from '@ionic/react';

import SearchBookingCard from '../components/booking/SearchBookingCard'
import FoundBookingCard from '../components/booking/FoundBooking'

import { useBookingStore } from '../stores/booking'

import styled from 'styled-components'


//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/

const Booking: React.FC = () => {

    const bookingAvailable = useBookingStore(state => state.bookingAvailable);
    const bookingData = useBookingStore(state => state.data);

    const bookingPageRef = useRef(null);

    return (
        <IonPage ref={bookingPageRef}>
            <IonHeader class="ion-no-border">
                <IonToolbar>
                    <IonTitle className="ion-text-start sdr-text-large sdr-text-color">Sundair Airport</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <StyledCardContainer>
                    <SearchBookingCard pageRef={bookingPageRef} />
                    {bookingAvailable ? <FoundBookingCard data={bookingData} /> : null}
                </StyledCardContainer>
            </IonContent>
        </IonPage>
    )
}

//-----------------------------------------------------------------------------/
// # 
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$                        
// #                     |  $$$$$$/                        
// #                      \______/    
// #  
//-----------------------------------------------------------------------------/

const StyledCardContainer = styled.div`
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
`;

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default Booking