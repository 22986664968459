/*
 * booking.tsx
 *
 * Description: Global Store for Bookings.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */
import { BASIC_URL, PNL_USER, PNL_PW } from '../tsx/config';
import { showToastError, showSpinner, hideSpinner, createHeaders } from '../tsx/utilities';

import create from 'zustand';
import axios from 'axios';

//-----------------------------------------------------------------------------/
// #   /$$$$$$$                      /$$       /$$
// #  | $$__  $$                    | $$      |__/
// #  | $$  \ $$  /$$$$$$   /$$$$$$ | $$   /$$ /$$ /$$$$$$$   /$$$$$$
// #  | $$$$$$$  /$$__  $$ /$$__  $$| $$  /$$/| $$| $$__  $$ /$$__  $$
// #  | $$__  $$| $$  \ $$| $$  \ $$| $$$$$$/ | $$| $$  \ $$| $$  \ $$
// #  | $$  \ $$| $$  | $$| $$  | $$| $$_  $$ | $$| $$  | $$| $$  | $$
// #  | $$$$$$$/|  $$$$$$/|  $$$$$$/| $$ \  $$| $$| $$  | $$|  $$$$$$$
// #  |_______/  \______/  \______/ |__/  \__/|__/|__/  |__/ \____  $$
// #    /$$$$$$   /$$                                        /$$  \ $$
// #   /$$__  $$ | $$                                       |  $$$$$$/
// #  | $$  \__//$$$$$$    /$$$$$$   /$$$$$$  /$$$$$$        \______/
// #  |  $$$$$$|_  $$_/   /$$__  $$ /$$__  $$/$$__  $$
// #   \____  $$ | $$    | $$  \ $$| $$  \__/ $$$$$$$$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$     | $$_____/
// #  |  $$$$$$/ |  $$$$/|  $$$$$$/| $$     |  $$$$$$$
// #   \______/   \___/   \______/ |__/      \_______/
// #
// #
// #
//-----------------------------------------------------------------------------/

export const useBookingStore = create((set) => ({
    data: '',
    idInvoice: '',
    bookingAvailable: false,
    addendum: '',
    inboundFlights: '',
    outwardFlights: '',
    passengers: '',
    tickets: '',

    actn_call_getBooking: (invoiceId: string) =>
        set(async () => {
            showSpinner();
            await axios
                .put(
                    BASIC_URL + 'rebooking/getbooking2',
                    { idInvoice: invoiceId },
                    { headers: createHeaders(PNL_USER, PNL_PW) }
                )
                .then(async (response: any) => {
                    if (response.data === '' || response.data.outwardFlights.length === 0) {
                        showToastError('No booking found..', 'Please try again!');
                        set({ bookingAvailable: false });
                        hideSpinner();
                    } else {
                        hideSpinner();
                        set({ data: response.data });
                        set({ idInvoice: response.data.idInvoice });
                        set({ addendum: response.data.addendum });
                        set({ inboundFlights: response.data.inboundFlights });
                        set({ outwardFlights: response.data.outwardFlights });
                        set({ passengers: response.data.passengers });
                        set({ tickets: response.data.tickets });
                        bookingSearchHistory.push(response.data);
                        bookingSearchHistoryId.push(response.data.idInvoice);
                        set({ bookingAvailable: true });
                    }
                })
                .catch((error) => {
                    hideSpinner();
                    showToastError('Something went wrong..', 'Please check your Network connections!');
                    set({ bookingAvailable: false });
                });
        }),

    actn_getBookingSearchHistory: (index: number) =>
        set(() => {
            set({ data: bookingSearchHistory[index] });
            set({ addendum: bookingSearchHistory[index].addendum });
            set({ idInvoice: bookingSearchHistory[index].idInvoice });
            set({ inboundFlights: bookingSearchHistory[index].inboundFlights });
            set({ outwardFlights: bookingSearchHistory[index].outwardFlights });
            set({ passengers: bookingSearchHistory[index].passengers });
            set({ tickets: bookingSearchHistory[index].tickets });
            set({ bookingAvailable: true });
        }),
}));

//-----------------------------------------------------------------------------/
// #   /$$$$$$$  /$$   /$$ /$$            /$$/$$$$$$$                      /$$       /$$
// #  | $$__  $$| $$$ | $$| $$           /$$/ $$__  $$                    | $$      |__/
// #  | $$  \ $$| $$$$| $$| $$          /$$/| $$  \ $$  /$$$$$$   /$$$$$$ | $$   /$$ /$$ /$$$$$$$   /$$$$$$
// #  | $$$$$$$/| $$ $$ $$| $$         /$$/ | $$$$$$$  /$$__  $$ /$$__  $$| $$  /$$/| $$| $$__  $$ /$$__  $$
// #  | $$____/ | $$  $$$$| $$        /$$/  | $$__  $$| $$  \ $$| $$  \ $$| $$$$$$/ | $$| $$  \ $$| $$  \ $$
// #  | $$      | $$\  $$$| $$       /$$/   | $$  \ $$| $$  | $$| $$  | $$| $$_  $$ | $$| $$  | $$| $$  | $$
// #  | $$      | $$ \  $$| $$$$$$$$/$$/    | $$$$$$$/|  $$$$$$/|  $$$$$$/| $$ \  $$| $$| $$  | $$|  $$$$$$$
// #  |__/      |__/  \__/|________/__/     |_______/  \______/  \______/ |__/  \__/|__/|__/  |__/ \____  $$
// #    /$$$$$$   /$$                                                                              /$$  \ $$
// #   /$$__  $$ | $$                                                                             |  $$$$$$/
// #  | $$  \__//$$$$$$    /$$$$$$   /$$$$$$  /$$$$$$                                              \______/
// #  |  $$$$$$|_  $$_/   /$$__  $$ /$$__  $$/$$__  $$
// #   \____  $$ | $$    | $$  \ $$| $$  \__/ $$$$$$$$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$     | $$_____/
// #  |  $$$$$$/ |  $$$$/|  $$$$$$/| $$     |  $$$$$$$
// #   \______/   \___/   \______/ |__/      \_______/
// #
// #
// #
//-----------------------------------------------------------------------------/

export const useBookingFromPnlStore = create((set) => ({
    data: '',
    idInvoice: '',
    bookingAvailable: false,
    addendum: '',
    inboundFlights: '',
    outwardFlights: '',
    passengers: '',
    tickets: '',

    actn_call_getBookingFromPnl: (invoiceId: string) =>
        set(async () => {
            await axios
                .put(
                    BASIC_URL + 'rebooking/getbooking2',
                    { idInvoice: invoiceId },
                    { headers: createHeaders(PNL_USER, PNL_PW) }
                )
                .then(async (response: any) => {
                    if (response.data === '') {
                        showToastError('No booking found..', 'Please try again!');
                        set({ bookingAvailable: false });
                    } else {
                        set({ data: response.data });
                        set({ idInvoice: response.data.idInvoice });
                        set({ addendum: response.data.addendum });
                        set({ inboundFlights: response.data.inboundFlights });
                        set({ outwardFlights: response.data.outwardFlights });
                        set({ passengers: response.data.passengers });
                        set({ tickets: response.data.tickets });
                        bookingSearchHistory.push(response.data);
                        bookingSearchHistoryId.push(response.data.idInvoice);
                        set({ bookingAvailable: true });
                    }
                })
                .catch((error) => {
                    showToastError('Something went wrong..', 'Please check your Network connection!');
                    set({ bookingAvailable: false });
                });
        }),

    actn_getBookingSearchHistory: (index: number) =>
        set(() => {
            set({ data: bookingSearchHistory[index] });
            set({ addendum: bookingSearchHistory[index].addendum });
            set({ idInvoice: bookingSearchHistory[index].idInvoice });
            set({ inboundFlights: bookingSearchHistory[index].inboundFlights });
            set({ outwardFlights: bookingSearchHistory[index].outwardFlights });
            set({ passengers: bookingSearchHistory[index].passengers });
            set({ tickets: bookingSearchHistory[index].tickets });
            set({ bookingAvailable: true });
        }),
}));

//-----------------------------------------------------------------------------/
// #   /$$   /$$ /$$            /$$
// #  | $$  | $$|__/           | $$
// #  | $$  | $$ /$$  /$$$$$$$/$$$$$$    /$$$$$$   /$$$$$$  /$$   /$$
// #  | $$$$$$$$| $$ /$$_____/_  $$_/   /$$__  $$ /$$__  $$| $$  | $$
// #  | $$__  $$| $$|  $$$$$$  | $$    | $$  \ $$| $$  \__/| $$  | $$
// #  | $$  | $$| $$ \____  $$ | $$ /$$| $$  | $$| $$      | $$  | $$
// #  | $$  | $$| $$ /$$$$$$$/ |  $$$$/|  $$$$$$/| $$      |  $$$$$$$
// #  |__/  |__/|__/|_______/   \___/   \______/ |__/       \____  $$
// #    /$$$$$$   /$$                                       /$$  | $$
// #   /$$__  $$ | $$                                      |  $$$$$$/
// #  | $$  \__//$$$$$$    /$$$$$$   /$$$$$$  /$$$$$$       \______/
// #  |  $$$$$$|_  $$_/   /$$__  $$ /$$__  $$/$$__  $$
// #   \____  $$ | $$    | $$  \ $$| $$  \__/ $$$$$$$$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$     | $$_____/
// #  |  $$$$$$/ |  $$$$/|  $$$$$$/| $$     |  $$$$$$$
// #   \______/   \___/   \______/ |__/      \_______/
// #
// #
// #
//-----------------------------------------------------------------------------/

export const bookingSearchHistory: Array<any> = [];
export const bookingSearchHistoryId: Array<any> = [];
