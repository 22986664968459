/*
 * LoginCard.tsx
 *
 * Description: Contains Login Card.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import React, { useState, useEffect } from 'react';
import { IonCard, IonCardContent, IonItem, IonCardHeader, IonButton, IonLabel, IonInput } from '@ionic/react';

import { useLoginStore } from '../stores/login';

import { useHistory } from 'react-router';

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/

const LoginCard: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const loggedIn = useLoginStore((state) => state.loggedIn);
    const actn_call_getLogin = useLoginStore((state) => state.actn_call_getLogin);

    const history = useHistory();

    /*******************************************************************************
     * useEffect()
     *
     * Desc: Checks wheter the User is logged in or not.
     * Note: Logged in? Get pushed to /booking!
     *
     */
    useEffect(() => {
        if (loggedIn === true) {
            setUsername('');
            setPassword('');

            history.push('/booking');
        }
    }, [loggedIn, history]);

    /*******************************************************************************
     * onClick_login()
     *
     * Desc: Initialises the API call with enteres username and password.
     * Note:
     *
     */
    const onClick_login = async () => {
        console.log('onClick_login', username, password);
        
        actn_call_getLogin(username, password);
    };

    return (
        <IonCard className="ion-no-margin ion-margin-vertical">
            <IonCardHeader>
                <p className="ion-no-margin ion-margin-top sdr-text-large sdr-font-weight-500">SunAirport</p>
            </IonCardHeader>
            <IonCardContent>
                <form>
                    <IonItem>
                        <IonLabel position="floating">Username</IonLabel>
                        <IonInput required autocomplete='username' name='username' onIonChange={(e) => setUsername(e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput type="password" autocomplete='current-password' name='password' required value={password} onIonChange={(e) => setPassword(e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonButton onClick={() => onClick_login()} className="ion-margin-vertical" expand="block">
                        Login
                    </IonButton>
                </form>
                {/* <p style={{display:"flex", justifyContent:"center"}}>Hilfe zum Login</p> */}
            </IonCardContent>
        </IonCard>
    );
};

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default LoginCard;
