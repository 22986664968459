/*
 * SearchBookingCard.tsx
 *
 * Description: Contains search Card for Bookings.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import React, { useState, useEffect, RefObject } from "react";
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton
} from "@ionic/react";

import {
  useBookingStore,
  bookingSearchHistoryId,
} from "../../stores/booking";

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/

interface SearchBookingCardProps {
  pageRef: RefObject<HTMLElement>;
}


const SearchBookingCard: React.FC<SearchBookingCardProps> = ({pageRef}) => {


  const actn_call_getBooking = useBookingStore(state => state.actn_call_getBooking);
  const actn_getBookingSearchHistory = useBookingStore(state => state.actn_getBookingSearchHistory);
  const actn_getInvoiceId = useBookingStore(state => state.idInvoice);
  const [invoiceId, setInvoiceId] = useState<string>(actn_getInvoiceId);
  // const [showHistory, setShowHistory] = useState<boolean>(false);

      /*******************************************************************************
    * onClick_showHistory()
    *
    * Desc: Opens or closes the modal showing a list with searched bookings.
    * Note: 
    *
    */
  // const onClick_showHistory = () => {
  //   setShowHistory(!showHistory)
  // }

      /*******************************************************************************
    * useEffect()
    *
    * Desc: Updates the input field if the Invoice ID changes in the store.
    * Note: Will be executed if you are looking for a booking via PNL for example.
    *
    */
  useEffect(() => {
    setInvoiceId(actn_getInvoiceId)
  }, [actn_getInvoiceId])

      /*******************************************************************************
    * onClick_searchBooking()
    *
    * Desc: Let you search for Bookings.
    * Note: Looks for the booking in the local storage or makes an API call depending
    *       whether the booking was already stored or not
    */
  const onClick_searchBooking = async () => {
      if (bookingSearchHistoryId.indexOf(+invoiceId) > -1) {
      actn_getBookingSearchHistory(bookingSearchHistoryId.indexOf(+invoiceId));
    } else {
      actn_call_getBooking(invoiceId);
    }
  };


  return (
    <IonCard className="ion-no-margin ion-margin-vertical">
      <IonCardHeader>
        {/* <div onClick={() => {onClick_showHistory()}} >
          <IonIcon class="ion-float-right" style={{ fontSize:"150%"}} src={"assets/icon/Replay-2.svg"} />
          <SearchHistoryModal showHistory={showHistory} pageRef={pageRef} />
        </div> */}
        <p className="ion-no-margin ion-margin-top sdr-text-large">Booking Request</p>
      </IonCardHeader>
      <IonCardContent>
        <IonItem>
          <IonLabel position="floating">Invoice ID</IonLabel>
          <IonInput
            value={invoiceId}
            type="number"
            onIonChange={(e) => setInvoiceId(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonButton onClick={() => onClick_searchBooking()} className="ion-margin-vertical" expand="block">
          Search Booking
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/

export default SearchBookingCard;
