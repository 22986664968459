/*
 * SpinnerStore.tsx
 *
 * Description:
 *
 * This file is part of: "http://apps.sundair.com/", "sunCrew2/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Christian Arp <christian.arp@sundair.com>
 * @author Michael Bröker <michael.broeker@sundair.com>
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */
import create from 'zustand';

/*******************************************************************************
 * useSpinnerStore()
 *
 * Desc: Create the Spinner Store.
 *
 * @param {boolean} showSpinner
 *
 */
type State = {
    showSpinner: boolean;
    actn_showSpinner: () => void;
    actn_hideSpinner: () => void;
};

export const useSpinnerStore = create<State>((set) => ({
    showSpinner: false,

    /*******************************************************************************
     * actn_showSpinner()
     *
     * Desc: Shows the spinner.
     * Note:
     *
     */
    actn_showSpinner: () => {
        set({ showSpinner: true });
    },

    /*******************************************************************************
     * actn_hideSpinner()
     *
     * Desc: Hides the spinner.
     * Note:
     *
     */
    actn_hideSpinner: () => {
        set({ showSpinner: false });
    },
}));
