/*
 * FoundPnlBlank.tsx
 *
 * Description: Contains PNL details.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import React, { useEffect, useState, RefObject, useRef } from 'react';

import { IonCard, IonItem, IonCardHeader, IonCardContent, IonLabel, IonRippleEffect, IonNote, IonIcon } from '@ionic/react';

import styled from 'styled-components';

import BookingModal from './BookingModal';

import { usePnlBlankStore } from '../../stores/pnlBlank';
import { bookingSearchHistoryId, useBookingFromPnlStore } from '../../stores/booking';

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/

interface FoundPnlBlankProps {
    pageRef: RefObject<HTMLElement>;
    searchValue: any;
}

const FoundPnlBlank: React.FC<FoundPnlBlankProps> = (props) => {
    const componentRef = useRef(null);

    const gl_pnlBlank = usePnlBlankStore((state) => state.pnlBlank);

    const bookingAvailable = useBookingFromPnlStore((state) => state.bookingAvailable);
    const actn_call_getBookingFromPnl = useBookingFromPnlStore((state) => state.actn_call_getBookingFromPnl);
    const actn_getBookingSearchHistory = useBookingFromPnlStore((state) => state.actn_getBookingSearchHistory);

    const [paxList, setPaxList] = useState([]);

    const [showBooking, setShowBooking] = useState(false);
    const [paxIndex, setPaxIndex] = useState<number>();

    /*******************************************************************************
     * useEffect()
     *
     * Desc: Sorts the PNL alphabetically if pnl state changes.
     * Note:
     *
     */
    useEffect(() => {
        const paxList = [].concat(gl_pnlBlank).sort((a: any, b: any) => (a.lastName > b.lastName ? 1 : -1));
        setPaxList(paxList);
    }, [gl_pnlBlank]);

    /*******************************************************************************
     * onClick_showBooking()
     *
     * Desc: Shows or hides the Booking Modal.
     * Note: Looks for the booking in the local storage or makes an API call depending
     *       whether the booking was already stored or not
     */
    const onClick_showBooking = async (paxIndex: number, invoiceId: number) => {
        if (bookingSearchHistoryId.indexOf(+invoiceId) > -1) {
            actn_getBookingSearchHistory(bookingSearchHistoryId.indexOf(+invoiceId));
        } else {
            actn_call_getBookingFromPnl(invoiceId);
        }
        setShowBooking(!showBooking);
        setPaxIndex(paxIndex);
    };

    return (
        <React.Fragment>
            <StyledIonCard ref={componentRef} className="ion-no-margin ion-margin-vertical">
                <IonCardHeader className="ion-no-margin sdr-text-large">
                    <div className="card-title">
                        <IonIcon src="./assets/icon/service.revHEAD.svg" />
                        <div>Seat and Service Reservation</div>
                    </div>
                </IonCardHeader>

                <IonCardContent>
                    {paxList.map((passenger: any, index: number) =>
                        (passenger['firstName'] + ' ' + passenger['lastName']).indexOf(props.searchValue) > -1 ? (
                            <IonItem
                                className="ion-activatable ripple-parent"
                                lines="none"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    onClick_showBooking(index, passenger['idInvoice']);
                                }}
                                key={index}
                            >
                                <IonIcon src="./assets/icon/service.revHEAD.svg" />

                                <IonLabel style={{ position: 'relative' }}>
                                    <h2 style={{ whiteSpace: 'break-spaces' }} className="sdr-font-weight-600">
                                        {passenger['firstName']} {passenger['lastName']}
                                    </h2>
                                    <h3 className="sdr-text-small">{passenger.idInvoice}</h3>
                                </IonLabel>

                                <StyledSalutation>
                                    {passenger.services.map(
                                        (service: any, index: number) =>
                                            service.categoryCode.toUpperCase() +
                                            ' ' +
                                            (service.categoryCode === 'seat' ? service.seatIdentifyer.number + service.seatIdentifyer.letter : '') +
                                            (index + 1 !== passenger.services.length ? ' | ' : ' - ')
                                    )}
                                    {passenger['salutation']}
                                </StyledSalutation>

                                {bookingAvailable && showBooking && paxIndex === index ? (
                                    <BookingModal showBooking={showBooking} idInvoice={passenger.idInvoice} pageRef={props.pageRef} />
                                ) : null}
                                <IonRippleEffect className="sdr-ripple-color-primary"></IonRippleEffect>
                            </IonItem>
                        ) : null
                    )}
                </IonCardContent>
            </StyledIonCard>
        </React.Fragment>
    );
};

//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$
// #                     |  $$$$$$/
// #                      \______/
// #
//-----------------------------------------------------------------------------/

/*******************************************************************************
 * StyledIonCard
 *
 */
const StyledIonCard = styled(IonCard)`
    ion-icon {
        width: 25px;
        height: 20px;
    }

    .card-title {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
`;

/*******************************************************************************
 * StyledSalutation
 *
 */
const StyledSalutation = styled(IonNote)`
    display: flex;
    justify-content: flex-end;
    text-align: end;
    font-size: 1.2rem;
    margin: 10px 0px;
    width: 40%;
    font-weight: 200;
    color: var(--ion-color-primary);
`;

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default FoundPnlBlank;
