/*
 * FoundBooking.tsx
 *
 * Description: Contains found Booking Details.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import React from 'react';
import { IonCard, IonCardHeader, IonCardContent, IonItem, IonIcon, IonLabel, IonNote } from '@ionic/react';
import styled from 'styled-components';

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/

interface FoundBookingCardProps {
    data: {
        inboundFlights: Array<string>;
        outwardFlights: Array<string>;
        passengers: Array<string>;
        tickets: Array<string>;
    };
}

const FoundBookingCard: React.FC<FoundBookingCardProps> = ({ data }) => {
    const inboundFlights = data.inboundFlights;
    const outwardFlights = data.outwardFlights;
    const passengers = data.passengers;
    const tickets = data.tickets;

    /*******************************************************************************
     * flightRoute()
     *
     * Desc: Shows the flight route of the selected booking.
     * Note:
     *
     */
    const flightRoute = (flightDirection: Array<string>) => {
        return flightDirection.map((flightDetails: any, index: number) => {
            const routeInfo =
                flightDetails['flightNo'] +
                ' ' +
                flightDetails['departureAirport']['iataCode'] +
                '-' +
                flightDetails['destinationAirport']['iataCode'] +
                ' ' +
                JSON.stringify(flightDetails['departureDateTime']).substr(1, 10);

            return <div key={index}>{routeInfo}</div>;
        });
    };

    /*******************************************************************************
     * paxDetails()
     *
     * Desc: Shows all booked PAX services including seat, infant and other services.
     * Note:
     *
     */
    const paxDetails = (flightDirection: Array<string>) => {
        return passengers.map((pax: any, index: number) => {
            const paxName = pax['firstName'] + ' ' + pax['lastName'];
            const salutation = pax['salutation'];
            return (
                <IonItem lines="none" key={index}>
                    <IonLabel>
                        <h2 className="sdr-font-weight-600">{paxName}</h2>
                        {seatService(pax['idPax'], flightDirection)}
                        {infantService(pax['idPax'], flightDirection)}
                        {service(pax['idPax'], flightDirection)}
                    </IonLabel>

                    <StyledSalutation>{salutation}</StyledSalutation>
                </IonItem>
            );
        });
    };

    /*******************************************************************************
     * seatService()
     *
     * Desc: Shows booked PAX seat services.
     * Note:
     *
     */
    const seatService = (paxId: string, flightDirection: Array<any>) => {
        return tickets.map((ticket: any) => {
            if (paxId === ticket['idPax'] && flightDirection[0].idFlight === ticket['idFlight'] && ticket.seatServices[0] !== undefined) {
                return ticket.seatServices.map((seat: any, index: number) => (
                    <h3 className="sdr-text-small" key={index}>
                        Seat: {seat.seatIdentifyer.number}
                        {seat.seatIdentifyer.letter}
                    </h3>
                ));
            } else {
                return null;
            }
        });
    };

    /*******************************************************************************
     * infantService()
     *
     * Desc: Shows booked PAX infant services.
     * Note:
     *
     */
    const infantService = (paxId: any, flightDirection: Array<any>) => {
        return tickets.map((ticket: any) => {
            if (paxId === ticket['idPax'] && flightDirection[0].idFlight === ticket['idFlight'] && ticket.infantServices[0] !== undefined) {
                return ticket.infantServices.map((infant: any, index: any) => (
                    <h3 className="sdr-text-small" key={index}>
                        Infant: {infant.firstName} {infant.lastName}
                    </h3>
                ));
            } else {
                return null;
            }
        });
    };

    /*******************************************************************************
     * service()
     *
     * Desc: Shows booked PAX services.
     * Note:
     *
     * @param {string} paxId (paxId)
     *
     */
    const service = (paxId: string, flightDirection: Array<any>) => {
        return tickets.map((ticket: any) => {
            if (paxId === ticket['idPax'] && flightDirection[0].idFlight === ticket['idFlight'] && ticket.services[0] !== undefined) {
                return ticket.services.map((service: any, index: any) => (
                    <h3 className="sdr-text-small" key={index}>
                        Service: {service.quantity} {service.description}
                    </h3>
                ));
            } else {
                return null;
            }
        });
    };

    return (
        <React.Fragment>
            {outwardFlights[0] ? (
                <IonCard className="ion-no-margin ion-margin-vertical sdr-font-family">
                    <IonCardHeader className="ion-no-margin ion-margin-top sdr-text-middle">
                        <div style={{ display: 'flex' }}>
                            <IonIcon style={{ fontSize: '150%' }} src={'assets/icon/Departing.1.svg'} />
                            {flightRoute(outwardFlights)}
                        </div>
                    </IonCardHeader>
                    <IonCardContent className="sdr-text-middle ion-text-wrap sdr-font-family">{paxDetails(outwardFlights)}</IonCardContent>
                </IonCard>
            ) : null}

            {inboundFlights[0] ? (
                <IonCard className="ion-no-margin ion-margin-vertical sdr-font-family">
                    <IonCardHeader style={{ display: 'flex' }} className="ion-no-margin ion-margin-top sdr-text-middle">
                        <IonIcon style={{ fontSize: '150%' }} src={'assets/icon/Arriving.1.svg'} />
                        {flightRoute(inboundFlights)}
                    </IonCardHeader>
                    <IonCardContent className="sdr-text-normal ion-text-wrap sdr-font-family">{paxDetails(inboundFlights)}</IonCardContent>
                </IonCard>
            ) : null}
        </React.Fragment>
    );
};

//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$
// #                     |  $$$$$$/
// #                      \______/
// #
//-----------------------------------------------------------------------------/

/*******************************************************************************
 * StyledSalutation
 *
 */
const StyledSalutation = styled(IonNote)`
    font-size: 1.5rem;
    font-weight: 200;
    color: var(--ion-color-primary);
    margin: 10px 0;
`;

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/

export default FoundBookingCard;
