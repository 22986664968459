/*
 * BadgesPnl.tsx
 *
 * Description: Contains Flight details for PNL in IonBadges.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import React, { useEffect } from 'react';
import { IonBadge } from '@ionic/react';

import { usePnlStore } from '../../stores/pnl';

import dayjs from 'dayjs';

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/

type BadgesProps = {
    paxList: Array<string>;
    flightNumber: string;
    flightDate: string;
};

/*******************************************************************************
 * BagdesPnl
 *
 * Desc: Counts PAX types (adults, children, infants) and stores them into Badges.
 * Note:
 *
 */
const BagdesPnl: React.FC<BadgesProps> = ({ paxList, flightNumber, flightDate }) => {
    const actn_setPaxNumbers = usePnlStore((state) => state.actn_setPaxNumbers);
    var adultNumbers = 0;
    var childNumbers = 0;
    var infNumbers = 0;
    var paxNumber = 0;

    paxList.forEach((passenger: any) => {
        if (passenger['salutation'] === 'MR' || passenger['salutation'] === 'MRS') {
            adultNumbers++;
        } else {
            childNumbers++;
        }

        passenger.services.forEach((service: any) => {
            if (service['code'] === 'INF') {
                infNumbers++;
            }
        });
        paxNumber++;
    });

    useEffect(() => {
        if (paxNumber === paxList.length) {
            actn_setPaxNumbers(adultNumbers, childNumbers, infNumbers);
        }
    }, [paxNumber]);

    return (
        <React.Fragment>
            <div>
                <IonBadge style={{ marginLeft: '5px' }}>{flightNumber.toUpperCase()}</IonBadge>
                <IonBadge style={{ marginLeft: '5px' }}>{dayjs(flightDate).format('DD.MM.YYYY')}</IonBadge>
            </div>
            <div>
                <IonBadge style={{ marginLeft: '5px' }}>{'Adults: ' + adultNumbers}</IonBadge>
                <IonBadge style={{ marginLeft: '5px' }}>{'Children: ' + childNumbers}</IonBadge>
                <IonBadge style={{ marginLeft: '5px' }}>{'Infants: ' + infNumbers}</IonBadge>
            </div>
            <div>
                <IonBadge style={{ marginLeft: '5px' }}>{'Total number of PAX: ' + (adultNumbers + childNumbers)}</IonBadge>
            </div>
        </React.Fragment>
    );
};

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/

export default BagdesPnl;
