/*
 * App.tsx
 *
 * Description: App Routing.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import Login from "./pages/login";
import Booking from "./pages/booking";
import Pnl from "./pages/pnl";


import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonTabs,
  IonLoading
} from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/global.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useLoginStore } from "./stores/login";
import { useSpinnerStore } from "./stores/SpinnerStore"

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/

const App: React.FC = () => {
  const loggedIn = useLoginStore((state) => state.loggedIn);
  const showSpinner = useSpinnerStore((state) => state.showSpinner)

  return (
    <IonApp>
      <IonReactHashRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route path="/login" exact>
              <Login />
            </Route>
            <Route path="/booking" exact>
              { loggedIn === true ? <Booking /> : <Redirect to="login" /> }
            </Route>
            <Route path="/pnl" exact>
              {loggedIn === true ? <Pnl /> : <Redirect to="login" /> }
            </Route>

            <Redirect from="/" to="login" exact />
          </IonRouterOutlet>
          {loggedIn === true ? (
              <IonTabBar slot="bottom">
                <IonTabButton tab="booking" href="/booking">
                  <IonLabel>Booking</IonLabel>
                </IonTabButton>
                <IonTabButton tab="pnl" href="/pnl">
                  <IonLabel>PNL</IonLabel>
                </IonTabButton>
              </IonTabBar>
          ) : ( <IonTabBar></IonTabBar>)}
        </IonTabs>
      </IonReactHashRouter>
      <ToastContainer />
      <IonLoading isOpen={showSpinner} spinner={null} cssClass="sdr-spinner" />
    </IonApp>
  );
};

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/

export default App;
