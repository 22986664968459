/*
 * utitlities.tsx
 *
 * Description: Collection of some useful global Functions.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */
import React from "react";
import { toast } from "react-toastify";

import { CONF_API_KEY } from "./config";

import { useSpinnerStore } from "../stores/SpinnerStore"

export const createHeaders = (username: string, password: string) => {
  let headers: Object;

  headers = {
    Authorization: "Basic " + btoa(username + ":" + password),
    apiKey: CONF_API_KEY,
  };

  return headers;
};

export const showToastError = (title: string, content: string) => {
  toast.error(
    <React.Fragment>
            <p className="sdr-text-normal sdr-font-weight-500 sdr-text-color-white sdr-font-family ion-no-margin">
                {title}
            </p>
            <p style={{ marginTop: "4px", marginBottom: "12px" }} className="sdr-text-normal sdr-text-color-white sdr-font-family">
                {content}
            </p>
    </React.Fragment>
  );
};



/*******************************************************************************
 * showSpinner()
 *
 * Desc: Shows the Sundair Loading Spinner.
 * Note:
 *
 */
export const showSpinner = () => {
  useSpinnerStore.getState().actn_showSpinner();
};

/*******************************************************************************
* hideSpinner()
*
* Desc: Hides the Sundair Loading Spinner.
* Note:
*
*/
export const hideSpinner = () => {
  useSpinnerStore.getState().actn_hideSpinner();
};