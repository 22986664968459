/*
 * login.tsx
 *
 * Description: Global Store for Login data.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import { CONF_LOGIN_URL } from '../tsx/config';
import { createHeaders, showToastError } from '../tsx/utilities';

import create from 'zustand';
import axios from 'axios';

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/

export const useLoginStore = create(set => ({
    loggedIn: false,

    actn_call_getLogin: (username: string, password: string) => set(async () => {

        await axios.get(CONF_LOGIN_URL, { headers: createHeaders(username, password) })
            .then((response) => {
                
                if (response.headers["air41-status"] === 'ERROR') {
                    switch (response.headers["air41-error"]) {
                        case '[105120][VALIDATION][invalid user and/or password]': throw new Error("Invalid User and/or Password");
                        default: throw new Error("Unknown Error");
                    }
                }

                set({loggedIn: true})
            })
            .catch((error) => {
                showToastError("Error: ", error.message);
            })
}),

    actn_resetLoginStore: () => set(() => {
        set({loggedIn: false})
    })

}))

