/*
 * BookingModal.tsx
 *
 * Description: Pop Up to show Booking details from selected PAX.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import React, { RefObject } from 'react';
import { IonModal, IonHeader, IonTitle, IonContent, IonButton, IonToolbar, IonItem, IonButtons } from '@ionic/react';

import FoundBooking from '../booking/FoundBooking';

import { useBookingFromPnlStore } from '../../stores/booking';

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/

type BookingModalProp = {
    showBooking: boolean;
    idInvoice: string;
    pageRef: RefObject<HTMLElement>;
    closeModal?: () => void;
};

const BookingModal: React.FC<BookingModalProp> = ({ showBooking, idInvoice, pageRef, closeModal }) => {
    const bookingData = useBookingFromPnlStore((state) => state.data);
    return (
        <IonModal isOpen={showBooking} presentingElement={pageRef.current!} swipeToClose={true} onDidDismiss={closeModal}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={closeModal}>Back</IonButton>
                    </IonButtons>
                </IonToolbar>

                <IonToolbar>
                    <IonTitle>Booking Overview</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonItem style={{ marginLeft: '2%' }}>Invoice ID: {idInvoice}</IonItem>
                <div>
                    <FoundBooking data={bookingData} />
                </div>
            </IonContent>
        </IonModal>
    );
};

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/

export default BookingModal;
