/*
 * pnl.tsx
 *
 * Description: Displays PNL Screen with Search & Found Card.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import React, { useRef } from 'react';
import { IonPage, IonToolbar, IonHeader, IonTitle, IonContent } from '@ionic/react';
import styled from 'styled-components';

import { usePnlStore } from '../stores/pnl';
import SearchPnlCard from '../components/pnl/SearchPnlCard';
import FoundPnl from '../components/pnl/FoundPnl';

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/

const Pnl: React.FC = () => {
    const pnlAvailable = usePnlStore((state) => state.pnlAvailable);

    const pnlPageRef = useRef(null);
    const pnlListRef = useRef(null);

    return (
        <IonPage ref={pnlPageRef}>
            <IonHeader class="ion-no-border">
                <IonToolbar>
                    <IonTitle className="ion-text-start sdr-text-large sdr-text-color">Sundair Airport</IonTitle>
                </IonToolbar>
            </IonHeader>
            <StyledContent>
                <StyledCardContainer>
                    <SearchPnlCard />

                    {pnlAvailable ? (
                        <div ref={pnlListRef}>
                            <FoundPnl pageRef={pnlPageRef} />
                        </div>
                    ) : null}
                </StyledCardContainer>
            </StyledContent>
        </IonPage>
    );
};

//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$
// #                     |  $$$$$$/
// #                      \______/
// #
//-----------------------------------------------------------------------------/

const StyledContent = styled(IonContent)`
    --padding-top: 0px;
`;

const StyledCardContainer = styled.div`
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
`;

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/

export default Pnl;
