/*
 * SearchPnlCard.tsx
 *
 * Description: Contains search Card for Bookings.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import React, { useEffect, useState } from 'react';
import { IonCard, IonCardHeader, IonCardContent, IonItem, IonLabel, IonInput, IonButton, IonDatetime, IonIcon, IonNote } from '@ionic/react';

import { usePnlStore } from '../../stores/pnl';
import { usePnlBlankStore } from '../../stores/pnlBlank';
import { showToastError } from '../../tsx/utilities';
import dayjs from 'dayjs';
import styled from 'styled-components';

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/

const SearchPnlCard: React.FC = () => {
    const [date, setDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
    const [flightNumber, setFlightNumber] = useState<string>();
    const [departureAirport, setDepartureAirport] = useState<string>();
    const [destinationAirport, setDestinationAirport] = useState<string>();
    const [showSearchCardContent, setShowSearchCardContent] = useState<boolean>(true);

    const actn_call_getPnl = usePnlStore((state) => state.actn_call_getPnl);
    const actn_call_getPnlBlank = usePnlBlankStore((state) => state.actn_call_getPnlBlank);
    const gl_pnlAvailable = usePnlBlankStore((state) => state.pnlAvailable);

    /*******************************************************************************
     * getFlightNumber()
     *
     * Desc: Stores the entered flight number into local state.
     * Note:
     *
     */
    const getFlightNumber = (e: string) => {
        setFlightNumber(e);
    };

    /*******************************************************************************
     * getFlightDate()
     *
     * Desc: Stores the entered flight date into local state.
     * Note:
     *
     */
    const getFlightDate = (flightDate: string) => {
        setDate(dayjs(flightDate).format('YYYY-MM-DD'));
    };

    /*******************************************************************************
     * getDepartureAirport()
     *
     * Desc: Stores the entered departure airport into local state.
     * Note:
     *
     */
    const getDepartureAirport = (e: string) => {
        setDepartureAirport(e);
    };

    /*******************************************************************************
     * getDestinationAirport()
     *
     * Desc: Stores the entered destination airport into local state.
     * Note:
     *
     */
    const getDestinationAirport = (e: string) => {
        setDestinationAirport(e);
    };

    /*******************************************************************************
     * onClick_searchPnl()
     *
     * Desc: Let you search for PNLs.
     * Note:
     *
     */
    const onClick_searchPnl = () => {
        if (flightNumber && date !== '') {
            actn_call_getPnl(flightNumber, date + 'T00:00:00Z', departureAirport, destinationAirport);
            actn_call_getPnlBlank(flightNumber, date + 'T00:00:00Z', departureAirport, destinationAirport);
        } else {
            showToastError('Please check your entered Values!', 'Flight Number begins with SR and contains up to 4 digits');
        }
    };

    /*******************************************************************************
     * useEffect()
     *
     * Desc: Closes the Search Field if a PNL is found.
     * Note:
     *
     */
    useEffect(() => {
        if (gl_pnlAvailable === true) {
            setShowSearchCardContent(false);
        }
    }, [gl_pnlAvailable]);

    return (
        <StyledIonCard showSearchCardContent={showSearchCardContent} className="ion-no-margin ion-margin-vertical">
            <IonCardHeader className="ion-no-margin sdr-text-large">
                <div className="card-title" onClick={() => setShowSearchCardContent(!showSearchCardContent)}>
                    <IonIcon className="searchCard-triangle" src="./assets/icon/Down-6.svg" />
                    <div>NEW PNL Request</div>
                </div>
            </IonCardHeader>

            <IonCardContent>
                <IonItem>
                    <IonLabel position="floating">Flight Number<StyledSup color='red'>*</StyledSup></IonLabel>
                    <IonInput className="ion-text-uppercase" type="text" onIonChange={(e) => getFlightNumber(e.detail.value!)}></IonInput>
                </IonItem>

                <IonItem>
                    <IonLabel position="floating">Departure Date<StyledSup color='red'>*</StyledSup></IonLabel>
                    <IonDatetime
                        placeholder="YYYY-MM-DD"
                        displayFormat="YYYY-MM-DD"
                        value={date}
                        min="2015"
                        max="2030"
                        onIonChange={(e) => getFlightDate(e.detail.value!)}
                    ></IonDatetime>
                </IonItem>

                <IonItem>
                    <IonLabel position="floating">Departure Airport <StyledSup color='rgb(166, 166, 166)'>1)</StyledSup></IonLabel>
                    <IonInput className="ion-text-uppercase" type="text" onIonChange={(e) => getDepartureAirport(e.detail.value!)}></IonInput>
                    <StyledIonNote slot="end">IATA 3LTR Code</StyledIonNote>
                </IonItem>

                <IonItem>
                    <IonLabel position="floating">Destination Airport <StyledSup color='rgb(166, 166, 166)'>1)</StyledSup></IonLabel>
                    <IonInput className="ion-text-uppercase" type="text" onIonChange={(e) => getDestinationAirport(e.detail.value!)}></IonInput>
                    <StyledIonNote slot="end">IATA 3LTR Code</StyledIonNote>
                </IonItem>

                <StyledIonNote>1) Use departure/destination airport for via/tria flights.</StyledIonNote>

                <IonButton onClick={() => onClick_searchPnl()} className="ion-margin-vertical" expand="block">
                    Search PNL
                </IonButton>
            </IonCardContent>
        </StyledIonCard>
    );
};

const StyledIonCard = styled(IonCard)<{ showSearchCardContent: boolean }>`
    .card-title {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    ion-card-content {
        display: ${(props) => (props.showSearchCardContent ? '' : 'none')};
    }

    .searchCard-triangle {
        margin-right: 8px;
        height: 15px;
        ${(props) => (props.showSearchCardContent ? '' : 'transform: rotate(-90deg)')}
    }
`;

const StyledIonNote = styled(IonNote)`
    font-size: 12px;
`

const StyledSup = styled.sup<{ color?: string }>`
    color: ${(props) => props.color};
`

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/

export default SearchPnlCard;
