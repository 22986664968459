/*
 * FoundPnl.tsx
 *
 * Description: Contains PNL details.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import React, { useEffect, useState, RefObject, useRef } from 'react';

import { IonCard, IonItem, IonCardHeader, IonCardContent, IonSearchbar, IonLabel, IonRippleEffect, IonNote, IonIcon } from '@ionic/react';
import { printOutline } from 'ionicons/icons';
import styled from 'styled-components';

import BadgesPnl from './BadgesPnl';
import BookingModal from './BookingModal';
import FoundPnlBlank from './FoundPnlBlank';

import { usePnlStore } from '../../stores/pnl';
import { bookingSearchHistoryId, useBookingFromPnlStore } from '../../stores/booking';
import { usePnlBlankStore } from '../../stores/pnlBlank';
import printJS from 'print-js';
import dayjs from 'dayjs';

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/

interface FoundPnlCardProps {
    pageRef: RefObject<HTMLElement>;
}

const FoundPnl: React.FC<FoundPnlCardProps> = ({ pageRef }) => {
    const [paxList, setPaxList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [showBooking, setShowBooking] = useState(false);
    const [paxIndex, setPaxIndex] = useState<number>();
    const [showBadges, setShowBadges] = useState<boolean>(true);

    const componentRef = useRef(null);

    const pnl = usePnlStore((state) => state.pnl);
    const flightNumber: string = usePnlStore((state) => state.flightNumber);
    const flightDate = usePnlStore((state) => state.flightDate);
    const paxNumbers = usePnlStore((state) => state.paxNumbers);

    const bookingAvailable = useBookingFromPnlStore((state) => state.bookingAvailable);
    const actn_call_getBookingFromPnl = useBookingFromPnlStore((state) => state.actn_call_getBookingFromPnl);
    const actn_getBookingSearchHistory = useBookingFromPnlStore((state) => state.actn_getBookingSearchHistory);

    const gl_pnlBlank = usePnlBlankStore((state) => state.pnlBlank);

    /*******************************************************************************
     * useEffect()
     *
     * Desc: Sorts the PNL alphabetically if pnl state changes.
     * Note:
     *
     */
    useEffect(() => {
        const paxList = [].concat(pnl).sort((a: any, b: any) => (a.lastName > b.lastName ? 1 : -1));
        setPaxList(paxList);
    }, [pnl]);

    /*******************************************************************************
     * searchPax()
     *
     * Desc: Stores the entered search value in upper cases into local state.
     * Note:
     *
     */
    const searchPax = (e: string) => {
        const searchInput = e.toUpperCase();
        setSearchValue(searchInput);
    };

    /*******************************************************************************
     * onClick_showBooking()
     *
     * Desc: Shows or hides the Booking Modal.
     * Note: Looks for the booking in the local storage or makes an API call depending
     *       whether the booking was already stored or not
     */
    const onClick_showBooking = async (paxIndex: number, invoiceId: number) => {
        if (showBooking === false) {
            if (bookingSearchHistoryId.indexOf(+invoiceId) > -1) {
                actn_getBookingSearchHistory(bookingSearchHistoryId.indexOf(+invoiceId));
            } else {
                actn_call_getBookingFromPnl(invoiceId);
            }
            setShowBooking(true);
            setPaxIndex(paxIndex);
        }
    };

    /*******************************************************************************
     * closeModal()
     *
     * Desc: Closes the Modal
     * Note:
     *
     */
    const closeModal = () => {
        setShowBooking(false);
    };

    /*******************************************************************************
     * printPnl()
     *
     * Desc: Prints the core information from PNL
     * Note: Print Area Line 196 - 213
     *
     */
    const printPnl = () => {
        printJS({
            printable: '1',
            type: 'html',

            repeatTableHeader: false,
            targetStyle: ['margin-bottom', 'margin-top', 'font-size'],
        });
    };

    return (
        <React.Fragment>
            <StyledIonCard
                showBadges={showBadges}
                className="ion-no-margin ion-margin-vertical"
                style={{ position: 'sticky', top: '0', zIndex: '10' }}
            >
                <IonIcon className="print-icon" onClick={() => printPnl()} icon={printOutline} />
                <IonCardHeader onClick={() => setShowBadges(!showBadges)} className="ion-no-margin sdr-text-large pointer">
                    <div className="card-title">
                        <IonIcon className="badges-triangle" src="./assets/icon/Down-6.svg" />
                        <div>Found Flight Details</div>
                    </div>
                </IonCardHeader>
                <IonCardContent>
                    <div className="flight-badges-container">
                        <BadgesPnl paxList={paxList} flightNumber={flightNumber} flightDate={flightDate} />
                    </div>

                    <IonSearchbar
                        onIonChange={(e) => {
                            searchPax(e.detail.value!);
                        }}
                    />
                </IonCardContent>
            </StyledIonCard>
            <StyledIonCard showBadges={true} ref={componentRef} className="ion-no-margin ion-margin-vertical">
                <IonCardHeader className="ion-no-margin sdr-text-large">
                    <div className="card-title">
                        <IonIcon src="./assets/icon/Man.svg" />
                        <div>Passenger Name List</div>
                    </div>
                </IonCardHeader>

                <IonCardContent>
                    {paxList.map((passenger: any, index: number) =>
                        (passenger['firstName'] + ' ' + passenger['lastName']).indexOf(searchValue) > -1 ? (
                            <IonItem
                                className="pnl-container ion-activatable ripple-parent"
                                lines="none"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    onClick_showBooking(index, passenger['idInvoice']);
                                }}
                                key={index}
                            >
                                {passenger['salutation'] === 'MR' ? <IonIcon src="./assets/icon/Man.svg" /> : ''}
                                {passenger['salutation'] === 'MRS' ? <IonIcon src="./assets/icon/Woman.svg" /> : ''}
                                {passenger['salutation'] === 'CHD' ? <IonIcon src="./assets/icon/Toddler.svg" /> : ''}
                                <IonLabel style={{ position: 'relative' }}>
                                    <h2 style={{ whiteSpace: 'break-spaces' }} className="sdr-font-weight-600">
                                        {passenger['firstName']} {passenger['lastName']}
                                    </h2>
                                    <h3 className="sdr-text-small">{passenger.idInvoice}</h3>
                                </IonLabel>

                                <StyledSalutation>
                                    {passenger.services.map(
                                        (service: any, index: number) =>
                                            service.categoryCode.toUpperCase() +
                                            ' ' +
                                            (service.categoryCode === 'seat' ? service.seatIdentifyer.number + service.seatIdentifyer.letter : '') +
                                            (index + 1 !== passenger.services.length ? ' | ' : ' - ')
                                    )}
                                    {passenger['salutation']}
                                </StyledSalutation>

                                {bookingAvailable && showBooking && paxIndex === index ? (
                                    <BookingModal
                                        showBooking={showBooking}
                                        idInvoice={passenger.idInvoice}
                                        pageRef={pageRef}
                                        closeModal={() => closeModal()}
                                    />
                                ) : null}
                                <IonRippleEffect className="sdr-ripple-color-primary"></IonRippleEffect>
                            </IonItem>
                        ) : null
                    )}
                    {/* PRINT AREA */}
                    <div style={{ overflow: 'hidden', height: '0', width: '10000px' }} id="1">
                        <h1 style={{ marginBottom: '10px' }}>
                            <div>PNL for Flight {flightNumber.toLocaleUpperCase()}. </div>
                            <div>
                                Printed on {dayjs().format('DD.MM.YYYY')} at {dayjs().format('HH:mm:ssZ[Z]')}
                            </div>

                            <div style={{ marginTop: '10px' }}>
                                Adults: {paxNumbers.adults}, Children: {paxNumbers.children}, Infants: {paxNumbers.infants}
                            </div>
                            <div>Total Number of Pax: {paxNumbers.adults + paxNumbers.children}</div>
                        </h1>
                        {paxList.map((passenger: any, index: number) => (
                            <p key={index} style={{ marginTop: '10px', fontSize: '12px' }}>
                                {passenger.idInvoice}, {passenger['salutation']} {passenger['firstName']} {passenger['lastName']}
                            </p>
                        ))}
                    </div>
                    {/* PRINT AREA */}
                </IonCardContent>
            </StyledIonCard>

            {gl_pnlBlank.length > 0 ? <FoundPnlBlank pageRef={pageRef} searchValue={searchValue} /> : null}
        </React.Fragment>
    );
};

//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$
// #                     |  $$$$$$/
// #                      \______/
// #
//-----------------------------------------------------------------------------/

/*******************************************************************************
 * StyledIonCard
 *
 */
const StyledIonCard = styled(IonCard)<{ showBadges: boolean }>`
    .print-icon {
        position: absolute;
        top: 14px;
        right: 8px;
        height: 25px;
        width: 25px;
        z-index: 1000;
        cursor: pointer;
    }

    ion-icon {
        width: 25px;
        height: 20px;
    }

    ion-card-header {
        display: flex;
        align-items: center;

        div {
            display: flex;
            align-items: center;
        }
    }
    .flight-badges-container {
        display: ${(props) => (props.showBadges ? '' : 'none')};
    }
    .badges-triangle {
        margin-right: 8px;
        height: 15px;
        ${(props) => (props.showBadges ? '' : 'transform: rotate(-90deg)')}
    }

    .card-title {
        display: flex;
        align-items: center;
    }
    .pointer {
        cursor: pointer;
    }
`;

/*******************************************************************************
 * StyledSalutation
 *
 */
const StyledSalutation = styled(IonNote)`
    display: flex;
    justify-content: flex-end;
    text-align: end;
    font-size: 1.2rem;
    margin: 10px 0px;
    width: 40%;
    font-weight: 200;
    color: var(--ion-color-primary);
`;

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default FoundPnl;
