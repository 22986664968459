/*
 * login.tsx
 *
 * Description: Global Store for PNLs.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

import creat from 'zustand';
import axios from 'axios';

import { PNL_USER, PNL_PW, CONF_PNL_BLANK_URL } from '../tsx/config';
import { createHeaders, showSpinner, hideSpinner } from '../tsx/utilities';

//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/

export const usePnlBlankStore = creat((set) => ({
    pnlBlank: [],
    flightNumber: '',
    flightDate: '',
    pnlAvailable: false,
    paxNumbers: {},

    actn_call_getPnlBlank: (flightNumber: string, flightDate: string, departureAirport: string, destinationAirport: string) =>
        set(async () => {
            showSpinner();
            await axios
                .put(CONF_PNL_BLANK_URL, 
                    {
                        flightNo: flightNumber, 
                        departureDate: flightDate,
                        departureAirport: departureAirport,
                        destinationAirport: destinationAirport  
                    }, 
                    { 
                        headers: createHeaders(PNL_USER, PNL_PW) 
                    })
                .then(async (response: any) => {
                    set({ pnlAvailable: false });
                    hideSpinner();
                    set({ pnlBlank: response.data });
                    set({ flightNumber: flightNumber });
                    set({ flightDate: flightDate });
                    set({ pnlAvailable: true });
                })
                .catch((error) => {
                    hideSpinner();
                    set({ flightNumber: '' });
                    set({ flightDate: '' });
                    set({ pnlAvailable: false });
                });
        }),

    actn_setPaxNumbers: (adults: number, children: number, infants: number) =>
        set(() => {
            set({ paxNumbers: { adults: adults, children: children, infants: infants } });
        }),
}));
