/*
 * config.tsx
 *
 * Description: Config File.
 *
 * This file is part of: "http://apps.sundair.com/", "Airports/Sundair"
 * Copyright 2020 Sundair GmbH
 * Contact:  http://www.sundair.com/
 * @author Phillip Alsleben <phillip.alsleben@sundair.com>
 *
 */

export const LIVE_OR_TEST = 'Live';

export const BASIC_TEST_URL = 'https://suncrewapp2.sundair.com/rest/'

export const BASIC_URL = 'https://sundair.com/rest/'

export const CONF_API_KEY = "037ae1f2ba244a2a93b24121743bfdd6";

export const CONF_LOGIN_URL = "https://sundair.com/rest/acm/acl/login";

export const CONF_BOOKING_URL = BASIC_URL + "rebooking/getbooking2";

export const CONF_PNL_URL = BASIC_URL + "pnl/flight/paxes";

export const CONF_PNL_URL_TEST = BASIC_URL + "pnl/flight/paxes";

export const CONF_PNL_BLANK_URL = BASIC_URL + "pnl/blank/paxes"

export const PNL_USER = "app_booking";

export const PNL_PW = "APP!sundair202"
